import { A, cache, createAsync, useParams } from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  onMount,
  Show,
  Suspense,
} from "solid-js";
import { GiftCard } from "~/components/pluto/gift_card";
import { Stepper } from "~/components/pluto/stepper";
import { usePluto } from "~/context/pluto";
import { getGiftById } from "~/server/apis/client_apis";
import {
  getPlutoGiftDetailsRouteData,
  PlutoGiftDetailsRouteData,
} from "~/server/data/pluto_gift_details_route_data";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

const getGiftDetailsRouteData$C = cache(
  getPlutoGiftDetailsRouteData,
  "pluto_gift_details"
);

export default function PlutoGiftCard() {
  const params = useParams();

  const routeData: Accessor<PlutoGiftDetailsRouteData | undefined> =
    createAsync(() => getGiftDetailsRouteData$C(params.giftId), {
      deferStream: true,
    });

  const { plutoGifts } = usePluto();

  // const [giftCardDetails, setGiftCardDetails] = createSignal(
  //   plutoGifts?.newGiftCardDetails ?? routeData()?.giftDetails
  // );

  // onMount(async () => {
  //   const giftDetails = await getGiftById(params.giftId);
  //   setGiftCardDetails(giftDetails);
  // });

  const [latestGiftCardDetails, setLatestGiftCardDetails] = createSignal(
    plutoGifts?.newGiftCardDetails ?? routeData()?.giftDetails
  );

  onMount(async () => {
    const giftDetails = await getGiftById(params.giftId);
    setLatestGiftCardDetails(giftDetails);
  });

  const giftCardDetails = createMemo(() => {
    return latestGiftCardDetails() ?? routeData()?.giftDetails;
  });

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <div class="flex h-full w-full flex-col overflow-y-auto md:w-[800px]">
        <Show when={giftCardDetails()} fallback={<DottedLoader color="#999" />}>
          <div class="relative flex min-h-14 items-center justify-center px-4 py-2">
            <A href="/gifts/new" class="absolute left-4 top-4">
              <PhosphorIcon
                name="arrow-left"
                fontSize={20}
                size="thin"
                class="cursor-pointer text-white"
              />
            </A>
            <p class="text-center text-smallBold uppercase tracking-[1.1px] text-white">
              Gift for {giftCardDetails()?.content.text.occasion.recipientName}
            </p>
          </div>

          {/* <Stepper activeStep={0} /> */}
          <div class="ml-20 flex w-auto flex-row">
            <div class="group flex-1 shrink basis-0">
              <div class="inline-flex min-h-7 w-full min-w-7 items-center align-middle">
                <span class="flex size-6 shrink-0 items-center justify-center rounded-full bg-[#00AF54]">
                  <PhosphorIcon
                    name="check"
                    fontSize={16}
                    size="bold"
                    class="text-white"
                  />
                </span>

                <div class="h-px w-full flex-1 bg-basePrimaryDark group-last:hidden"></div>
              </div>
              <div class="-ml-2 mt-1">
                <span class="text-medium text-[#00AF54]">Created</span>
              </div>
            </div>

            <div class="group flex-1 shrink basis-0">
              <div class="inline-flex min-h-7 w-full min-w-7 items-center align-middle">
                <span class="flex size-6 shrink-0 items-center justify-center rounded-full bg-basePrimaryDark text-f12Bold text-white ">
                  2
                </span>
                <div class="h-px w-full flex-1 bg-basePrimaryDark group-last:hidden"></div>
              </div>
              <div class="-ml-2 mt-1">
                <span class="text-medium text-textNormal">Top-up</span>
              </div>
            </div>

            <div class="group flex-1 shrink basis-0">
              <div class="inline-flex min-h-7 w-full min-w-7 items-center align-middle">
                <span class="flex size-6 shrink-0 items-center justify-center rounded-full bg-basePrimaryDark text-f12Bold text-white ">
                  3
                </span>
                <div class="h-px w-full flex-1 bg-basePrimaryDark group-last:hidden"></div>
              </div>
              <div class="-ml-2 mt-1">
                <span class="text-medium text-textNormal">Share</span>
              </div>
            </div>
          </div>
          <div class="mx-auto mb-4">
            <GiftCard
              giftDetails={() => giftCardDetails()!}
              flipOnButton={true}
              flipOnCard={true}
              showActions={true}
              showActionsBg={false}
              editPath={`/gifts/${giftCardDetails()?.id}/edit`}
            />
          </div>
        </Show>
      </div>
    </Suspense>
  );
}
